import React from "react";
import { Layout } from "../components";
import styled from "styled-components";
import { animated, Spring, config } from "react-spring";

const TextWrapper = styled.div`
  padding: 2rem 1.5rem;
  max-width: 55rem;
  margin: 0 auto;
  margin-bottom: 1rem;

  p {
    font-size: 18px;
  }

  img {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const ServicesPage = () => (
  <Layout>
    <Spring
      native
      config={config.slow}
      delay={750}
      from={{ opacity: 0, transform: "translate3d(0, -30px, 0)" }}
      to={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
    >
      {(props) => (
        <animated.div style={props}>
          <TextWrapper>
            <h1>Leistungen</h1>
            <p>
              Kreative Ideen für Marken und Unternehmen, herausragende
              Gestaltung und Texte, die man gerne liest. All das sind wichtige
              Bausteine für Ihren Erfolg, denn als Unternehmen und Dienstleister
              brauchen Sie ein deutliches Profil, das auffällt, und Botschaften,
              die merkfähig, sympathisch und relevant kommuniziert werden.
            </p>

            <p>
              Der Job: Grafisch, textlich und konzeptionell beraten und Lösungen
              anbieten. Die Mission: Alles auf den Punkt bringen. Ein ganzes
              Unternehmen symbolisiert in einem einzigen Zeichen, einem Logo,
              einer Farbe. Eine bewährte Haltung, eine neue Dienstleistung oder
              eine ganze Produktpalette verdichtet in einem Satz, einer
              Broschüre, einer Anzeige oder auch in einem digitalen Newsletter.
              Vorteile klarmachen, visuell überzeugen, für Begeisterung sorgen
              und manchmal auch alles zusammen und dabei möglichst immer aus
              einem Guss. Dann wird’s auch was mit der Marke!
            </p>

            <h2>Grafikdesign + Webdesign</h2>
            <p>
              &rarr; Corporate Design, Logoentwicklung und -gestaltung,
              Gestaltung von Werbemitteln, Broschüren, Katalogen, Websites,
              Bannern, Apps
            </p>

            <h2>Markenkommunikation + Werbekampagnen</h2>
            <p>
              &rarr; Konzepte + Konzeptlayouts, Kampagnenmechaniken für Off- und
              Online
            </p>

            <h2>Produktion Film/Foto</h2>
            <p>
              &rarr; Film- und Fotoideen, Storyboard, Briefing und
              Supervision/Organisation
            </p>

            <h2>Text</h2>
            <p>&rarr; Konzept, Headlines, Copy (Entwurf)</p>

            <h2>Branchen (bisherige Schwerpunkte):</h2>
            <p>
              Banken & Versicherungen, Travel & Touristik, Schulen & Kultur,
              Car, Sport & Social, Food & Beverages, Health Care, Fast Moving
              Consumer Goods (FMCG) –selbstverständlich nicht nur B2C, sondern
              auch B2B.
            </p>
          </TextWrapper>
        </animated.div>
      )}
    </Spring>
  </Layout>
);

export default ServicesPage;
